.plus-icon {
	position: absolute;
    z-index: 10;
    top: 28%;
    /* left: 32%; */
    font-size: 120px;
}
.plus-icon img {
    width:95%;
}
#landscape #container-circles {
    position: absolute;
    left: 90%;
    top: 30%;
    z-index: 10000;
    /* bottom: 90px; */
}
.react-html5-camera-photo>img, .react-html5-camera-photo>video {
    width: 100%;
    height: 100%;
}
@media screen and (min-width: 700px) {
.plus-icon {
    top: 10%;
    /* left: 45%; */
    font-size: 120px;
}
#landscape #container-circles {
    position: absolute;
    left: 90%;
    top: 30%;
    /* bottom: 90px; */
}
}