  @media screen and (orientation:portrait) { 
        #landscape {
          display: none;
        }
        #portrait {
          display: block;
          margin-top: 40%;
          margin-left: 20%;
        }
    }

    @media screen and (orientation:landscape) { 
      #landscape {
          display: block;
          width:100%;
          height:66.67vh;
        }
        #portrait {
          display: none;
        }
    }